.Spinner {
  text-align: center;
}

.Spinner > div {
  width: 2rem;
  height: 2rem;
  margin: 1rem;
  background-color: #3273dc;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.2s infinite ease-in-out both;
}
.Spinner .Spinner--bounce1 {
  animation-delay: -0.32s;
}
.Spinner .Spinner--bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
