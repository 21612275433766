html,
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: sans-serif;
  color: #333;
}
h1 {
  font-size: 1.6rem;
}
h2 {
  font-size: 1.25rem;
  margin-top: 2rem;
}
p {
  line-height: 1.5;
  margin: 1rem 0 2rem;
}
label {
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: #707377;
  font-size: 1rem;
}
label.active {
  font-weight: bold;
  color: #000;
}
.form-error {
  color: #f00;
}
.error label {
  color: #f00;
}
.error .validation {
  margin: 0.5rem 0;
  color: #f00;
}
input[type="text"],
input[type="number"] {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.5rem;
  display: block;
  font-size: 1rem;
  padding: 1.25rem 1rem;
  height: 2.25em;
  border-radius: 0;
  -webkit-appearance: none;
  border: 0;
  background: #dce2e9;
}
input[type="text"]:focus,
input[type="number"]:focus {
  outline: none;
  background: #ff0;
}
.error input[type="text"],
.error input[type="number"] {
  background: #faa;
}
input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline;
}
label {
  cursor: pointer;
}
button {
  margin-top: 1rem;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  padding: 1rem 0.7rem;
  border: 0;
  font-size: 1.25rem;
  padding: 0.5rem;
}
a,
a:visited,
a:link {
  text-decoration: none;
  display: inline-block;
  padding: 0.01rem 0;
  border-bottom: solid 2px #000;
}
ul {
  margin: 1rem 0 0 2rem;
  padding: 0;
}
li {
  margin: 1rem 0;
  line-height: 1.5;
  padding: 0;
}

.App button {
  background: #007bff;
}

.App--ultra a,
.App--ultra a:visited,
.App--ultra a:link {
  color: #8625ac;
  border-color: #8625ac;
}

.App--ultra button {
  background: #6c0097;
}

.App--mint a,
.App--mint a:visited,
.App--mint a:link {
  color: #68af85;
  border-color: #68af85;
}
.App--mint button {
  background: #68af85;
}

.field {
  margin: 0 0 2rem;
}
