.App {
  max-width: 100em;
  margin: 1rem;
}

.bar {
  background: rgb(255, 255, 255);
  text-align: right;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px, rgba(0, 0, 0, 0.1) 0px 0px 20px;
}

.agreement {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  padding: 3rem 1rem 1rem;
}
